import React, { useState, useEffect } from "react";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import Seo from "./Head";
import { collection, getDocs, query, where } from "firebase/firestore";
import { dataBase } from "../firebaseConfig";

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchCategories = async () => {
      try {
        // Asume que tienes una colección de productos
        const productsRef = collection(dataBase, "productos");
        // Obtenemos todos los productos activos
        const activeProductsQuery = query(
          productsRef,
          where("estado", "==", "activo")
        );
        const productsSnapshot = await getDocs(activeProductsQuery);
        console.log("Products Snapshot: ", productsSnapshot);

        // Crear un objeto para almacenar el primer producto de cada categoría
        const categoriesMap = {};

        productsSnapshot.docs.forEach((doc) => {
          const product = doc.data();
          const category = product.categoria;
          console.log("Product: ", product);

          // Si esta es la primera vez que vemos esta categoría, guardamos el producto
          if (!categoriesMap[category]) {
            categoriesMap[category] = {
              id: doc.id,
              name: category,
              image: product.imagenes[0]?.url || "",
              slug: category.toLowerCase().replace(/\s+/g, "-"),
            };
          }
        });

        console.log("Categories Map: ", categoriesMap);

        // Convertimos el objeto en un array
        const categoriesData = Object.values(categoriesMap);
        console.log("Categories Data: ", categoriesData);

        setCategories(categoriesData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching categories", error);
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  console.log("Categories: ", categories);

  return (
    <>
      <Seo
        title={"Tienda Mobilem"}
        description={"Tienda de electrodomésticos y artículos para el hogar"}
        image={`${window.location.origin}/static/media/LogoNuevo.c219d0bf4f0348ebfdc3.png`}
        pathSlug={window.location.href}
      />
      {isLoading ? <Loader /> : ""}
      <div className="row">
        {categories.map((category) => (
          <div key={category.id} className="col-12 col-md-4">
            <Link to={`/categoria/${category.slug}`} className="row promoHome">
              <div className="square-image-container">
                <img
                  src={category.image}
                  alt="promo"
                  className="col-12 img-fluid"
                  onLoad={() => setIsLoading(false)}
                />
              </div>
              <div className="col-1">
                <div className="bordeGrisDer col-1"></div>
              </div>
              <h1 className="textoBajo text-center">{category.name}</h1>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
