import React from "react";
import { Link } from "react-router-dom";

export const Item = ({
  id = "",
  titulo = "",
  imagenes = "",
  precio = 0,
  formaPago = "",
  categoria = "",
}) => {
  const tituloId = titulo.replace(/_|#| |@|<>/g, "_");

  return (
    <div className="" key={id}>
      <Link to={`/detalle/${id}#${tituloId}`} className="link">
        {/* {categoria === "sillones"  */}
        {/* <div className="cartel text-center">
          <span className="ahorro">
            Contado ${formaPago.contadoValue.toLocaleString()}
          </span>
        </div> */}
        {/* : <div className='cartel'><span className='ahorro'>ahorro ${Math.round(precio-formaPago.contadoValue)}</span></div> */}
        {/* } */}
        <img
          src={imagenes[0].url}
          alt={`Foto de ${titulo}`}
          className="img-fluid mx-0 px-0"
        />
        <h4 className="text-center negrita mt-2">{titulo}</h4>
        <p className="text-center">
          {/* <span className="letraGris me-2">
            Precio:  */}

          <span className="negrita">${precio.toLocaleString()} </span>
          {/* </span> */}

          {/* <br />
          {categoria === "sillones" || categoria === "otros" ? (
            <span className="letraGris me-2">
              6 cuotas sin interés de
              <span className="negrita">
                {" "}
                ${Math.round(formaPago.seisValue / 6).toLocaleString()}{" "}
              </span>
            </span>
          ) : (
            <span className="letraGris me-2">
              3 cuotas sin interés de
              <span className="negrita">
                {" "}
                ${Math.round(formaPago.tresValue / 3).toLocaleString()}{" "}
              </span>
            </span>
          )} */}
        </p>
      </Link>
    </div>
  );
};
