import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ItemListContainer from '../components/ItemListContainer';
import Cart from './Cart';
import ItemDetailContent from './ItemDetailContent';
import Contactos from './Contactos'
import Nosotros from './Nosotros';
import Home from './Home'
import Show from './Show'
import NavMenu from './NavMenu';
import Nav from './Nav';
import { BannerPromo } from './BannerPromo';
import { useLocation } from 'react-router-dom';

const Main = () => {
const location = useLocation().pathname
console.log(location)
    return (
        <>
        {(location !== "/admin")?
        <>
        <Nav></Nav>
        <NavMenu></NavMenu>
        <div className="container-fluid">
            <Routes >
                <Route path='/' element={<Home />} />
                <Route path='/categoria/' element={<ItemListContainer />} />
                <Route path='/categoria/:nombreCategoria' element={<ItemListContainer />} />
                <Route path='/detalle/:idProducto' element={<ItemDetailContent />} />
                <Route path='/carrito' element={<Cart />} />
                <Route path='/contacto' element={<Contactos />} />
                <Route path='/nosotros' element={<Nosotros />} />
                <Route path='/admin' element={<Show />} />
            </Routes>
        </div>
        <BannerPromo></BannerPromo>
        </>
        :<div className="container-fluid">
        <Routes >
            <Route path='/' element={<Home />} />
            <Route path='/categoria/' element={<ItemListContainer />} />
            <Route path='/categoria/:nombreCategoria' element={<ItemListContainer />} />
            <Route path='/detalle/:idProducto' element={<ItemDetailContent />} />
            <Route path='/carrito' element={<Cart />} />
            <Route path='/contacto' element={<Contactos />} />
            <Route path='/nosotros' element={<Nosotros />} />
            <Route path='/admin' element={<Show />} />
        </Routes>
    </div>}
        </>
    );
};

export default Main;
