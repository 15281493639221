import React, { useState, useContext } from "react";
import { CartContext } from "../context/CartContext";
import ItemCount from "./ItemCount";
import Carousel from "./Carousel";
import ModalCreditos from "./ModalCreditos";
import FormaPago from "./FormaPago";
import Seo from "./Head";

export const ItemDetail = ({ itemDetail }) => {
  const { addToCart, cantidadSeleccionada } = useContext(CartContext);

  const onAdd = (cantidad) => {
    addToCart(itemDetail, cantidad, formaPago);
  };
  const cantidadEnCart = cantidadSeleccionada(itemDetail.id);

  const [count, setCount] = useState("");

  const traerCount = (count) => {
    setCount(count);
  };
  var formaPago = "";
  const handleChangeValue = () => {
    let e = document.getElementById("select");
    formaPago = e.value;
  };

  const doce = `12 cuotas de $${Math.round(
    (itemDetail.formaPago.doceValue / 12) * count
  ).toLocaleString()} con tarjeta de credito bancaria `;
  const seis = `6 cuotas de $${Math.round(
    (itemDetail.formaPago.seisValue / 6) * count
  ).toLocaleString()} con tarjeta de credito bancaria `;
  const tres = `3 cuotas de $${Math.round(
    (itemDetail.formaPago.tresValue / 3) * count
  ).toLocaleString()} con tarjeta de credito bancaria `;
  const personal = `4 cuotas de $${Math.round(
    (itemDetail.formaPago.personalValue / 4) * count
  ).toLocaleString()} con crédito personal `;
  const contado = `Efectivo, débito, transferencia: $${Math.round(
    itemDetail.formaPago.contadoValue * count.toLocaleString()
  )}`;
  const noBancarias = "Tarjetas de crédito no bancarias consultar.";

  return (
    <>
      <Seo
        title={itemDetail.titulo}
        description={itemDetail.precio}
        // image={`${itemDetail.imagenes[0].replace("..", `${window.location.origin}`)}`}
        pathSlug={window.location.href}
      />

      <div className="row">
        <div
          className="card col-12 col-md-6 ps-md-5 pe-md-5 pe-3 ps-3 mt-3"
          key={itemDetail.id}
        >
          <Carousel>
            {itemDetail.imagenes.map((img) => (
              <div>
                <img
                  src={img.url}
                  alt={itemDetail.titulo}
                  className="img-fluid"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="card datos mt-3 col-12 col-md-6">
          <div>
            <h1>{itemDetail.titulo}</h1>
            <h2> $ {itemDetail.precio.toLocaleString()} </h2>
          </div>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: itemDetail.descripcion }}
          />

          <div className="mt-3">
            {" "}
            {/* <h4>
              <i className="bi bi-credit-card-2-back me-2"></i>FORMA DE PAGO{" "}
            </h4>
            <FormaPago
              formasPago={[doce, seis, tres, personal, contado, noBancarias]}
              traerFormaPago={handleChangeValue}
            />
            <br /> */}
            <h4>
              <i class="bi bi-truck me-2"></i>ENVÍOS POR LA ZONA SIN COSTO
            </h4>
            <h4 style={{ textTransform: "uppercase" }}>
              <i class="bi bi-person-check me-2"></i>
              <ModalCreditos />
              CREDITOS PERSONALES
            </h4>
            <hr />
          </div>
          <h4 className="text-center text-md-start mt-3">
            <ItemCount
              traerCount={traerCount}
              stock={itemDetail.stock}
              initial={cantidadEnCart === undefined ? 1 : cantidadEnCart}
              onAdd={onAdd}
            />
          </h4>
        </div>
      </div>
    </>
  );
};
